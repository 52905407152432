import { useCallback, useState } from "react";
import { createRoot } from "react-dom/client";
import usePartySocket from "partysocket/react";
import APP_VERSION from "../version.json";
import type { PartialAIResponse } from "../party/server";

interface Bot {
  roomId: string;
  url: string;
  status: string;
}

interface TranscriptWord {
  text: string;
  start_time: number;
  end_time: number;
}

interface TranscriptUpdate {
  speaker: string;
  speaker_id: number;
  words: TranscriptWord[];
}

interface AIQuestionAnswer {
  question: string;
  answer: string;
  context: string;
  timestamp: number;
}

function BotRoom({ roomId }: Readonly<{ roomId: string }>) {
  const [transcript, setTranscript] = useState<TranscriptUpdate[]>([]);
  const [roomBot, setRoomBot] = useState<Bot | null>(null);
  const [aiHistory, setAIHistory] = useState<AIQuestionAnswer[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [streamingResponse, setStreamingResponse] =
    useState<PartialAIResponse | null>(null);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);

  const botSocket = usePartySocket({
    host: PARTYKIT_HOST,
    room: roomId,
    onOpen() {
      console.log("WebSocket connection opened for room:", roomId);
    },
    onClose() {
      console.log("WebSocket connection closed for room:", roomId);
    },
    onError(error) {
      console.error("WebSocket error for room:", roomId, error);
    },
    onMessage(event) {
      const data = JSON.parse(event.data);
      console.log("Received message:", data);
      switch (data.type) {
        case "FULL_TRANSCRIPT":
          setTranscript(data.payload);
          break;
        case "TRANSCRIPT_UPDATE":
          setTranscript((prev) => [...prev, data.payload]);
          break;
        case "STATUS_UPDATE":
          setRoomBot(data.payload);
          break;
        case "AI_QUESTION_ANSWER":
          setAIHistory((prevHistory) => [...prevHistory, data.payload]);
          break;
        case "AI_HISTORY":
          setAIHistory(data.payload);
          break;
        case "AI_STREAM":
          setIsStreaming(true);
          setStreamingResponse(data.payload);
          break;
        case "AI_STREAM_END":
          setIsStreaming(false);
          setStreamingResponse(data.payload);
          setAIHistory((prevHistory) => [
            ...prevHistory,
            {
              question: data.payload.question || "Unknown question",
              answer: data.payload.answer,
              timestamp: Date.now(),
            },
          ]);
          break;
        case "ERROR":
          setError(data.payload.message);
          setIsStreaming(false);
          console.error("Received error from server:", data.payload.message);
          break;
        default:
          console.warn("Unhandled message type:", data.type);
      }
    },
  });

  const handleSimulateConversation = useCallback(() => {
    botSocket.send(JSON.stringify({ type: "SIMULATE_CONVERSATION" }));
  }, [botSocket]);

  const renderTranscript = () => {
    return transcript.map((update, index) => (
      <div key={index}>
        <strong>
          {update.speaker} (ID: {update.speaker_id}):
        </strong>
        {update.words.map((word, wordIndex) => (
          <span key={wordIndex}> {word.text}</span>
        ))}
      </div>
    ));
  };

  const handleGetHelp = useCallback(() => {
    botSocket.send(JSON.stringify({ type: "GET_HELP" }));
    setIsStreaming(true);
    setStreamingResponse(null);
    setError(null);
  }, [botSocket]);

  return (
    <div>
      <h2>Transcript</h2>
      <p>Bot Status: {roomBot?.status}</p>
      {error && <div style={{ color: "red" }}>Error: {error}</div>}

      <button onClick={handleGetHelp} disabled={isStreaming}>
        {isStreaming ? "Getting AI Help..." : "Get AI Help"}
      </button>
      <button onClick={handleSimulateConversation}>
        Simulate Conversation
      </button>

      {streamingResponse && (
        <div>
          <h3>AI Help</h3>
          {streamingResponse.question && (
            <p>
              <strong>Question:</strong> {streamingResponse.question}
            </p>
          )}
          <p>
            <strong>Answer:</strong> {streamingResponse.answer}
          </p>
          <p>
            <strong>Context:</strong> {streamingResponse.context}
          </p>
          {streamingResponse.isComplete && <p>(Response complete)</p>}
        </div>
      )}

      {aiHistory.length > 0 && (
        <div>
          <h3>Past Help</h3>
          {aiHistory.map((item, index) => (
            <div key={index}>
              <p>
                <strong>Question:</strong> {item.question}
              </p>
              <p>
                <strong>Answer:</strong> {item.answer}
              </p>
              <p>
                <strong>Context:</strong> {item.context}
              </p>
              <p>
                <small>Time: {new Date(item.timestamp).toLocaleString()}</small>
              </p>
            </div>
          ))}
        </div>
      )}
      <div>{renderTranscript()}</div>
    </div>
  );
}

function App() {
  const [url, setUrl] = useState("");
  const [activeBots, setActiveBots] = useState<Bot[]>([]);
  const [selectedBot, setSelectedBot] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const mainSocket = usePartySocket({
    host: PARTYKIT_HOST,
    room: "main-room",
    onMessage(event) {
      const data = JSON.parse(event.data);
      console.log("Received message:", data);
      switch (data.type) {
        case "ACTIVE_BOTS":
          setActiveBots(
            data.payload.map(([roomId, botInfo]) => ({ roomId, ...botInfo }))
          );
          break;
        case "BOT_CREATED":
          setActiveBots((prev) => [
            ...prev,
            { ...data.payload, status: "created" },
          ]);
          break;
        case "BOT_DELETED":
          setActiveBots((prev) =>
            prev.filter((bot) => bot.roomId !== data.payload.roomId)
          );
          if (selectedBot === data.payload.roomId) {
            setSelectedBot(null);
          }
          break;
        case "BOT_CREATION_ERROR":
          console.error("Bot creation error:", data.payload.error);
          setError(data.payload.error);
          break;
      }
    },
  });

  const createBot = () => {
    mainSocket.send(JSON.stringify({ type: "CREATE_BOT", payload: { url } }));
    setUrl("");
  };

  const deleteBot = (roomId: string) => {
    mainSocket.send(
      JSON.stringify({ type: "DELETE_BOT", payload: { roomId } })
    );
  };

  const selectBot = (roomId: string) => {
    setSelectedBot(roomId);
  };

  const leaveBot = () => {
    setSelectedBot(null);
  };

  return (
    <main>
      <h1>🤖 Call Bots</h1>
      {error && <div style={{ color: "red" }}>{error}</div>}

      {selectedBot === null && (
        <>
          <h2>Create New Bot</h2>
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter call URL"
          />
          <button onClick={createBot}>Create Bot</button>

          <h2>Active Bots</h2>
          <ul>
            {activeBots.map((bot) => (
              <li key={bot.roomId}>
                {bot.url} - Status: {bot.status}
                <button onClick={() => selectBot(bot.roomId)}>Select</button>
                <button onClick={() => deleteBot(bot.roomId)}>Delete</button>
              </li>
            ))}
          </ul>
        </>
      )}
      {selectedBot && (
        <>
          <button onClick={leaveBot}>🔙 All bots</button>
          <BotRoom roomId={selectedBot} />
        </>
      )}
      <p>Version: {APP_VERSION.version}</p>
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
